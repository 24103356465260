export const initialValues = {
    nome: "",
    codigoInterno: "",
    grupo_produto_id: '',
    unidade_produto_id: '',
    movimentaEstoque: false,
    habilitaNotaFiscal: true,
    codigoBarras: "",
    peso: '',
    largura: '',
    altura: '',
    comprimento: '',
    comissao: 0,
    descricao: "",
    valorCusto: 0,
    despesasAdicionais: 0,
    outrasDespesas: 0,
    custoFinal: 0,
    estoqueMinimo: '',
    estoqueMaximo: '',
    quantidadeAtual: 0,
    foto_produto: [],
    fotoPrincipal: [],
    ncm: "",
    cest: "",
    cfop: "",
    pesoLiquido: '',
    pesoBruto: '',
    numeroFci: '',
    valorAproxTribut: '',
    valorPixoPis: '',
    valorFixoPisSt: '',
    valorFixoCofins: '',
    valorFixoCofinsSt: '',
    porcentagem_lucro_produto: [],
    cliente_id: '',
    fornecedores_id: []
  };