const statuses = [
  {
    class: "online",
    descricao: 'Online',
    selected: true
  },
  {
    class: "busy",
    descricao: 'Ocupado',
    selected: false
  },
  {
    class: "away",
    descricao: 'Ausente',
    selected: false
  },
  {
    class: "offline",
    descricao: 'Invisível',
    selected: false
  }
]

export default statuses;